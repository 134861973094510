/**
 * raccourci pour un appel ajax rapide
 */
(function ($) {
  'use strict';
  $.fancybox.ajax = function (url, params, title) {
    // gestion du titre
    title = title || '';
    // traitement href
    url += url.indexOf('?') > 0 ? '' : '?';
    if (params) {
      $.each(params, function (k, v) {
        url += '&' + k + '=' + encodeURIComponent(v);
      });
    }
    // appel ajax
    $.fancybox.open([
      {
        href: url,
        type: 'ajax',
        title: title,
        beforeShow: function () {
          $('.fancybox-inner').initialize();
        },
      }]);
  };
}(jQuery));
