/**
 * Compte le nombre de charactere et l'affiche dans le label
 */
function countcharacter(id, label) {
    var val = $('#' + id).val().length;
    $('label[for=\'' + id + '\']').html(label + ' <em>*</em> (' + val + ' car)');
}

/**
 * Cache la pop up jqueryui
 */
function hideloading() {
    $.mobile.loading('hide');
}

/**
 * Remplie un select a partir d'un requete json
 */
$.fn.loadSelect = function (url, data) {
    var e = this;
    $.post(url, data,
        function (d) {
            if (typeof(d) !== 'object') {
                d = $.parseJSON(d);
            }
            e.empty();
            //Du coup on ecrit en dur la balise
            $.each(d.data, function (i, o) {
                e.append($('<option>', {
                    value: o.value,
                    text: o.label,
                }));
            });
        }
    );
};

/**
 * Autocomplete un input
 */
$.fn.loadAutocomplete = function (url, data) {
    var e = this;
    $.post(url, data, function (d) {
            if (typeof(d) !== 'object') {
                d = $.parseJSON(d);
            }
            e.autocomplete({source: d.data, minLength: '0', autoFocus: 'true'});
        }
    );
};

/**
 * Remplie un datalist a partir d'un requete json
 */
$.fn.loadList = function (url, data) {
    var e = this;
    $.post(url, data,
        function (d) {
            if (typeof(d) !== 'object') {
                d = $.parseJSON(d);
            }
            e.empty();
            $.each(d.data, function (i, o) {
                e.append('<option value="' + o + '">');
            });
        }
    );
};

/**
 * Remplie un datalist a partir d'un requete json
 */
$.fn.loadDataList = function (url) {
    var e = this;
    $.get(url, function (d) {
            if (typeof (d) !== 'object') {
                d = $.parseJSON(d);
            }
            e.empty();
            $.each(d.data, function (i, o) {
                e.append('<option value="' + o + '">');
            });
        }
    );
};

/**
 * Fonction uniquement compatible avec Bootstrap
 * Permet de check un input et de verifier si il est vide ou pas si il l'est on genere l'erreur avec bootstrap!
 */
$.fn.checkIsEmpty = function () {
    //boolean retourné apres check
    var r = false;
    if (!$(this).val()) {
        //On ajoute les attribut pour genrer l'erreur
        $(this).addClass('form-error');
        $(this).attr('data-placement', 'bottom');
        $(this).attr('data-original-title', 'Merci de bien vouloir renseigner ce champ');
        r = true;
    } else {
        //On enleve tout les attribut concernant l'erreur
        $(this).removeClass('form-error');
        $(this).removeAttr('data-placement');
        $(this).removeAttr('data-original-title');
        $(this).closest('div').removeClass('has-error').removeClass('has-feedback');
        $(this).closest('div').find('span').remove();
    }
    $('body').initialize();
    return r;
};

/**
 * rajout d'une fermeture sur un click exterieur pour les overlay
 */
(function ($, undefined) {
    //Check que ui est defini
    if (typeof $.ui == 'undefined') {
        return;
    }
    $.extend($.ui.dialog.prototype.options, {
            // on met par defaut une fermeture sur les fentre modal
            open: function () {
                if ($(this).dialog('option', 'modal') == true) {
                    var that = this;
                    $.each($.ui.dialog.overlay.instances, function () {
                        $(this).click(function () {
                            $(that).dialog('close');
                        });
                    });
                }
            },
        }
    );
}(jQuery));

/**
 * initialisation sur chargement ajax
 */
(function ($, undefined) {
    //Check que ui est defini
    if (typeof $.ui == 'undefined') {
        return;
    }
    $.extend($.ui.tabs.prototype.options, {
            // initialisation du panel chargé
            load: function (e, ui) {
                $(ui.panel).initialize();
            },
        }
    );
}(jQuery));

/**
 * fait apparaitre un dialog
 */
function dialogurl(url, args, title, width, modal) {
    // traitement de l'url
    url += url.indexOf('?') > 0 ? '' : '?';
    if (args) {
        $.each(args, function (k, v) {
            url += '&' + k + '=' + encodeURIComponent(v);
        });
    }
    $.fancybox({href: url, title: title, type: 'ajax'});
    return false;
}

/**
 * redirection
 */
$.extend({
    location: function (url) {
        $(location).attr('href', url);
    },
});

/**
 * inititlize un contenu
 * charge tout les element necessitant du javascript
 */
$.fn.initialize = function () {
    $(this).find('[class=button]:not([class=orange1])').each(function () {
        $(this).button({
            icons: {
                primary: $(this).attr('data-icon-primary') || null,
                secondary: $(this).attr('data-icon-secondary') || null,
            },
            text: $(this).attr('data-icon-only') !== 'true',
            disabled: $(this).attr('data-button-disabled') === 'true',
        });
    });

    /**
     * Buttons
     */
    $(this).find('.btn').each(function () {
        if ($(this).hasClass('confirm')) {
            $(this).unbind('click').click(function (event) {
                if (!confirm($(this).attr('data-confirm')
                        ? $(this).attr('data-confirm')
                        : 'Etes vous sur de vouloir réaliser cette action?')) {
                    event.preventDefault();
                    event.stopImmediatePropagation();
                }
            });
        }
    });

    $(this).find('.navigation-action-link').each(function () {
        $(this).button({disabled: $(this).attr('navigation-link-disabled') === 'true'});
    });

    if ($.fn.buttonset !== undefined) {
        $(this).find('.buttonset').buttonset();
        // fix poour datatable
        $(this).find('.DTTT_container').buttonset();
    }

    //ACCORDEONS
    if ($.fn.accordion !== undefined) {
        $(this).find('.accordion').accordion();
    }

    //Rating
    if ($.fn.rating !== undefined) {
        $(this).find('.rating-tooltip').rating();
    }

    /**
     * Setup the Tabs
     */
    if ($.fn.tabs !== undefined) {
        $(this)
            .find('.tabs')
            .tabs()
            .find('> .ui-tabs-nav')
            .removeClass('ui-corner-all')
            .addClass('ui-corner-top');
        $(this)
            .find('.sidebar-tabs')
            .tabs()
            .addClass('ui-tabs-vertical ui-helper-clearfix')
            .find('li')
            .removeClass('ui-corner-top')
            .addClass('ui-corner-all');
    }

    // FANCY BOX
    //TODO: remove event handling through a global variable
    function tryHandlingFancyboxEvent(eventName) {
        return function () {
            if (typeof(fancyboxHandlers) === 'undefined') {
                return;
            }
            if (typeof(fancyboxHandlers[eventName]) === 'function') {
                fancyboxHandlers[eventName]();
                fancyboxHandlers[eventName] = null;
            }
        };
    }

    $(this).find('.fancybox-ajax').each(function () {
        $(this).fancybox({
            type: 'ajax',
            beforeShow: function () {
                $(this.inner.get(0)).initialize();
            },
            beforeClose: tryHandlingFancyboxEvent('beforeClose'),
            afterClose: tryHandlingFancyboxEvent('afterClose'),
            afterShow: tryHandlingFancyboxEvent('afterShow'),
            helpers: {
                overlay: {
                    closeClick: $(this).data('overlay-close') !== false,
                },
            },
        });
    });
    $(this).find('.fancybox-ajax-no-scrolling').each(function () {
        $(this).fancybox({
            type: 'ajax',
            beforeShow: function () {
                $(this.inner.get(0)).initialize();
            },
            beforeClose: tryHandlingFancyboxEvent('beforeClose'),
            afterClose: tryHandlingFancyboxEvent('afterClose'),
            afterShow: tryHandlingFancyboxEvent('afterShow'),
            helpers: {
                overlay: {
                    closeClick: $(this).data('overlay-close') !== false,
                },
            },
            'scrolling': 'no',
        });
    });

    // chargement d'un callback
    $(this).find('.callback-ajax').off('click').click(function (event) {
        $.getScript($(this).attr('href'));
        event.preventDefault();
        event.stopImmediatePropagation();
    });

    $(this).find('.callback-ajax-json').off('click').click(function (event) {
        $.getJSON($(this).attr('href'));
        event.preventDefault();
        event.stopImmediatePropagation();
    });

    $(this).find('.fancybox-iframe').each(function () {
        $(this).fancybox({
            type: 'iframe',
        });
    });

    $('.select2-container').removeClass('form-control').width('100%');

    /**
     * Gestion des erreurs de formulaires
     */
    var formErrors = $(this).find('.form-error');
    if (formErrors.length) {
        formErrors
            .tooltip()
            .after('<span class="glyphicon glyphicon-remove form-control-feedback"></span>')
            .parent()
            .addClass('form-group has-error has-feedback');
    }

    // fix pour les tableau
    $(this).find('.dataTable').css('width', '');
    $('.ajax-content .form-ajax').each(function () {
        var $form = $(this);
        $form.ajaxForm({
            success: function (html) {
                $form.closest('.ajax-content')
                    .html(html)
                    .initialize();
            },
        });
    });
    return this;
};
