document.addEventListener('DOMContentLoaded', () => {
    function addItem(event, element, index) {
        event.preventDefault();

        let prototype = element.dataset.prototype;
        let prototypeName = new RegExp(element.dataset.prototypeName, 'g');

        prototype = prototype.replace(prototypeName, index);

        const list = element.querySelector('[data-form-collection="list"]');
        list.insertAdjacentHTML('beforeend', prototype);

        document.dispatchEvent(new CustomEvent('collection-form-add', { detail: { element: list.lastElementChild } }));

        return index + 1;
    }

    function deleteItem(event) {
        event.preventDefault();

        event.target.closest('[data-form-collection="item"]').remove();
    }

    function initializeCollectionForm(element) {
        let limit = element.dataset.limit;
        let list = element.querySelector('[data-form-collection="list"]');
        let index = list.children.length;

        element.addEventListener('click', (event) => {
            if (event.target.closest('[data-form-collection="add"]')) {
                if (!limit || list.children.length < limit) {
                    index = addItem(event, element, index);
                } else {
                    event.preventDefault();
                }
            } else if (event.target.closest('[data-form-collection="delete"]')) {
                deleteItem(event);
            }
        });
    }

    document.querySelectorAll('[data-form-type="collection"]').forEach(element => initializeCollectionForm(element));
});