$(function () {
    $('input[name*="zipcode"]').change(function () {
        if (this.value.length === 5) {
            $('input[name*="city"]').val('');
            autoCompleteCities($(this).val());
        }
    });
});
var targetCities = 'datalist[id*="city"]';
var autoCompleteCities = function (zipCode) {
    if ($(targetCities).length > 0) {
        $(targetCities).loadDataList("/ajax/cityautocomplete/" + zipCode);
    }
};