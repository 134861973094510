(($) => {
    $(document).ready(() => {
        let universeInput = $('#project_category_group_id');
        let categoryInput = $('#project_category_id');

        if (universeInput && categoryInput) {
            universeInput.change(function() {
                let form = $(this).closest('form');
                let url = form.attr('data-update-categories-ajax') + '/' + universeInput.val();

                $.ajax({
                    url :  url,
                    type: 'GET',
                    success: function(data) {
                        categoryInput.empty();
                        $.each(data, function(key, value) {
                            categoryInput.append($("<option></option>").attr("value", value).text(key));
                        });
                    }
                });
            });
        }
    })
})(jQuery);