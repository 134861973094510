var showNotifications = function (response, type) {
    try {
        response = JSON.parse('' + response);
        if (response.messages) {
            $.each(response.messages, function (i, message) {
                var text = message.title + (message.body ? '<br/>' + message.body : '');
                if (type === 'success') {
                    toastr.success(text, '');
                } else {
                    toastr.error(text, '');
                }
            });
        }
        if (response.callbacks) {
            $.each(response.callbacks, function (i, callback) {
                eval(callback);
            });
        }
        if (response.redirect) {
            window.location.replace(response.redirect);
        }
        if (response.close) {
            $.fancybox.close();
        }
        if (response.reload) {
            setTimeout(function () {
                window.location.reload()
            }, response.timeout);
        }
        if (response.script) {
            new Function(response.scriptData)(); //run script
            toastr.success(response.message, '');
        }
    } catch (err) {
        // Do nothing
    }
};

// Automatically display messages included in AJAX responses
$(document).ajaxSuccess(function (event, xhr) {
    showNotifications(xhr.responseText, 'success');
}).ajaxError(function (event, xhr) {
    showNotifications(xhr.responseText, 'error');
    try {
        var response = JSON.parse(xhr.responseText);
        if (typeof response === 'object') {
            // Do now show fancybox for json error
            $.fancybox.close();
        }
    } catch (e) {
    }
});