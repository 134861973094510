(() => {
    document.addEventListener("DOMContentLoaded", () => {
        const passwordFields = document.querySelectorAll('input[type="password"]');

        passwordFields.forEach((passwordFiled) => {
            let seePasswordIcon = passwordFiled.parentElement.querySelector('.see-password-icon');

            seePasswordIcon.addEventListener('click', () => {
                const type = passwordFiled.getAttribute('type');

                if (type === 'password') {
                    passwordFiled.setAttribute('type', 'text');
                    seePasswordIcon.setAttribute('title', 'Masquer le mot de passe');
                    seePasswordIcon.classList.remove('fa-eye');
                    seePasswordIcon.classList.add('fa-eye-slash');
                } else {
                    passwordFiled.setAttribute('type', 'password');
                    seePasswordIcon.setAttribute('title', 'Afficher le mot de passe');
                    seePasswordIcon.classList.remove('fa-eye-slash');
                    seePasswordIcon.classList.add('fa-eye');
                }
            });
        });
    });
})();