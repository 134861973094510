document.addEventListener('DOMContentLoaded', () => {
    const displayUploadedImage = function displayUploadedImage(input) {
        if (input.files && input.files[0]) {
            if (input.files[0].name.match(/\.(jpg|jpeg|png|gif)$/i)) {
                const reader = new FileReader();

                reader.onload = (event) => {
                    const imagePreview = input.closest('.js-image-preview');
                    const imagePreviewContainer = imagePreview.querySelector('.js-image-preview-container');
                    const image = imagePreview.querySelector('.image');

                    if (image) {
                        image.src = event.target.result;
                    } else {
                        const img = document.createElement('img');
                        img.className = 'img-thumbnail image';
                        img.width = 150;
                        img.height = 150;
                        img.src = event.target.result;

                        imagePreviewContainer.replaceChildren(img);
                    }
                };

                reader.readAsDataURL(input.files[0]);
            } else {
                const imagePreviewContainer = document.querySelector('.js-image-preview-container');
                imagePreviewContainer.innerHTML = input.files[0].name;
            }
        }
    };

    document.querySelectorAll('.js-image-preview').forEach((imagePreview) => {
        imagePreview.addEventListener('change', (event) => {
            if (event.target.tagName.toLowerCase() === 'input' && event.target.type === 'file') {
                displayUploadedImage(event.target);
            }
        });
    });

    document.addEventListener('collection-form-add', (event) => {
        let element = event.detail.element;
        let imagePreview = element.querySelector('.js-image-preview');

        if (imagePreview) {
            imagePreview.addEventListener('change', (event) => {
                if (event.target.tagName.toLowerCase() === 'input' && event.target.type === 'file') {
                    displayUploadedImage(event.target);
                }
            });
        }
    });
});