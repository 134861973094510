function initHomeSearchBar(allCategories) {

    function categoryMatches(searchTerm, category) {
        searchTerm = searchTerm.toLowerCase();
        return category.category_name?.toLowerCase().includes(searchTerm) ||
               category.category_title_label?.toLowerCase().includes(searchTerm) ||
               category.sub_categories?.toLowerCase().includes(searchTerm);
    }

    function searchCategories(searchValue) {
        let filteredCategories = allCategories.filter(category => categoryMatches(searchValue, category));

        let resultsContainer = $('.search-results');
        resultsContainer.empty();

        if(!filteredCategories.length) {
            let defaultCategoryNames = ['plomberie', 'electricite', 'chauffage-electrique', 'maconnerie', 'menuiserie-interieure-sur-mesure'];
            filteredCategories = allCategories.filter(category => defaultCategoryNames.includes(category.category_postjob_link));
            resultsContainer.append(`
                <div class="search-result-empty"> 
                    <strong>Aucun résultat</strong>
                    <p>Essayez les catégories ci-dessous :</p>
                </div>
            `);
        }
        
        if (searchValue && filteredCategories.length > 0) {
            // Create results container
            // Add each result
            filteredCategories.forEach(category => {
                let fullCategoryName =  category.category_title_label + ' - ' + category.category_name;
                let categoryNameMatch = fullCategoryName.replace(new RegExp(searchValue, 'gi'), match => `<u><strong>${match}</strong></u>`);
                let subCategoriesMatch = category.sub_categories.replace(new RegExp(searchValue, 'gi'), match => `<u><strong>${match}</strong></u>`);
                resultsContainer.append(`
                    <div class="search-result" 
                            onclick="window.location.href='/devis-travaux/devis-${category.category_postjob_link}'">
                        <div class="search-result-name">${categoryNameMatch}</div>
                        <div class="search-result-label">${subCategoriesMatch}</div>
                    </div>
                `);
            });
        }
    }

    let trades = [
        'un plombier...',
        'un électricien...',
        'un chauffagiste...',
        'un maçon...',
        'un menuisier...',
        'un peintre...',
        'un plâtrier...',
        'un carreleur...',
        'un couvreur...',
    ];
    let currentTradeIndex = 0;
    let currentLetterIndex = 0;
    let placeholderPrefix = "Je recherche ";

    function animatePlaceholder() {
        const animatedInput = document.getElementById('placeholder-animated');
        const currentWord = trades[currentTradeIndex];
        
        if (currentLetterIndex <= currentWord.length) {
            animatedInput.placeholder = placeholderPrefix + currentWord.substring(0, currentLetterIndex);
            currentLetterIndex++;
            setTimeout(animatePlaceholder, 50);
        } else {
            setTimeout(() => {
                currentLetterIndex = 0;
                currentTradeIndex = (currentTradeIndex + 1) % trades.length;
                animatePlaceholder();
            }, 1000);
        }
    }

    // Handle clicks outside search results to close them
    $(document).on('click', function(event) {
        if (!$(event.target).closest('.search-container').length) {
            $('.search-results').empty();
            $('.search-bar input').val('');
        }
    });

    $(document).ready(function() {
        $('.search-bar input').on('keyup', function() {
            var searchValue = $(this).val();
            searchCategories(searchValue);
        });
        animatePlaceholder();
    });
}