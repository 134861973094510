$(document).ready(function () {
    var params = {
        utilsScript: "/build/js/utils.js",
        initialCountry: "fr",
        preferredCountries: ['fr'],
        separateDialCode: true,
        formatOnDisplay: false,
        autoPlaceholder: "off",
    };

    var phoneCallingCodeObj = $(".phone_calling_code");
    var cellphoneCallingCodeObj = $(".cellphone_calling_code");
    var faxCallingCodeObj = $(".fax_calling_code");

    var inputPhone = document.querySelector(".contact_phone_field");
    if (inputPhone) {
        var inputPhoneInstance = window.intlTelInput(inputPhone, params);

        if (phoneCallingCodeObj.val() != "") {
            inputPhoneInstance.setCountry(phoneCallingCodeObj.val());
        }
    }

    var inputCellphone = document.querySelector(".contact_cellphone_field");
    if (inputCellphone) {
        var inputCellPhoneInstance = window.intlTelInput(inputCellphone, params);

        if (cellphoneCallingCodeObj.val() != "") {
            inputCellPhoneInstance.setCountry(cellphoneCallingCodeObj.val());
        }
    }

    var inputFax = document.querySelector(".contact_fax_field");
    if (inputFax) {
        var inputFaxInstance = window.intlTelInput(inputFax, params);

        if (faxCallingCodeObj.val() != "") {
            inputFaxInstance.setCountry(faxCallingCodeObj.val());
        }
    }

    $(".btn-submit-with-country-phone").on('click', function () {
        if (phoneCallingCodeObj.length) {
            phoneCallingCodeObj.val(inputPhoneInstance.getSelectedCountryData().iso2);
        }

        if (cellphoneCallingCodeObj.length) {
            cellphoneCallingCodeObj.val(inputCellPhoneInstance.getSelectedCountryData().iso2);
        }

        if (faxCallingCodeObj.length) {
            faxCallingCodeObj.val(inputFaxInstance.getSelectedCountryData().iso2);
        }
    });
});