(($) => {
    $(document).ready(() => {
        // MenuMobile
        $('#hamburger').click(function() {
            $('#hamburger').toggleClass('active');
            $('#nav-menu').toggleClass('active');
            $('.log-block').toggleClass('active');

            // Reset opacity when menuMobile isn't displayed
            if ($('#page-content').hasClass('dropDownOpen')) {
                $('#page-content').removeClass("dropDownOpen");
                $('.all-advice-block').removeClass("dropDownOpen");
            }
        });

        // DropDown CurrentMenu
        $('.all-advice-block').click(function() {
            $('.all-advice-block').toggleClass("dropDownOpen");
            $('#page-content').toggleClass("dropDownOpen");
        });

        // Hide DropDownMenu when user click on #current-page
        $('#page-content').click(function() {
            $('.all-advice-block').removeClass("dropDownOpen");
            $('#page-content').removeClass("dropDownOpen");
        });
    })
})(jQuery);